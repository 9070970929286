.wrap {
    position: relative;
}

.animeCard--popup {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #26a2f9ff;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
}

.wrap:hover .animeCard--popup {
    visibility: visible;
    overflow-y: scroll;
    opacity: 1;
}

.animeCard--popupElement {
    transition: 0.2s;
    transform: translateY(1em);
    padding: 20px;
}

.wrap:hover .animeCard--popupElement {
    transform: translateY(0);
}
